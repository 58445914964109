<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>자원관리</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <ReturnAddPopup v-if="pop.isShow" @close="pop.isShow=false"></ReturnAddPopup>
                <ReturnCancelPopup v-if="pop.isShow" @close="pop.isShow=false"></ReturnCancelPopup>
                <CarrotTitle title="교재 반납 관리">
                    - 반납교재 권수 수정 및 삭제는 ‘요청’ 상태일 때만 가능합니다.<br>
                    - 교재 명을 클릭하면 요청상세내용을 보실 수 있습니다.
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <table class="table-row table-serach">
                                <tbody>
                                    <tr>
                                        <th width="180">완료/취소일</th>
                                        <td>
                                            <input type="text" class="w-80px"> ~
                                            <input type="text" class="w-80px mr-20">
                                        </td>
                                        <th width="180">교재명</th>
                                        <td>
                                            <input type="text" class="w-80per">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="180">고객사명</th>
                                        <td>
                                            <input type="text" class="w-80per">
                                        </td>
                                        <th width="180">요청자</th>
                                        <td>
                                            <select name="" id="" class="w-40per">
                                                <option value="">팀 선택</option>
                                            </select>
                                            <select name="" id="" class="w-50per ml-10">
                                                <option value="">매니저 선택</option>
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button class="btn-default float-right mt-10">검색</button>
                            <div class="clear"></div>

                            <div class="mt-40 mb-20">
                                <select class="w-200px float-left">
                                    <option value="">구분 전체</option>
                                </select>
                                <select class="w-200px float-left ml-20">
                                    <option value="">반납현황 전체</option>
                                </select>
                                <div class="clear"></div>
                            </div>
                            
                            <table class="table-col">
                                <colgroup>
                                    <col width="80">
                                    <col width="100">
                                    <col width="100">
                                    <col width="210">
                                    <col width="100">
                                    <col width="150">
                                    <col width="120">
                                    <col width="100">
                                    <col width="120">
                                    <col width="120">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>구분</th>
                                        <th>유/무상</th>
                                        <th>교재명</th>
                                        <th>수량</th>
                                        <th>요청자</th>
                                        <th>출고일</th>
                                        <th>반납수량<br>(유상/무상)</th>
                                        <th>최근 반납일</th>
                                        <th>반납현황</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>5</td>
                                        <td>내부</td>
                                        <td>유상</td>
                                        <td><span class="btn-view">교재명교재명</span></td>
                                        <td>2</td>
                                        <td>Amy K(김은지)</td>
                                        <td>2019-00-00</td>
                                        <td>0/0</td>
                                        <td>-</td>
                                        <td>
                                            <button class="btn-default">반납하기</button>
                                            <button class="btn-default mt-10">반납취소</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>외부</td>
                                        <td>무상</td>
                                        <td><span class="btn-view">교재명교재명</span></td>
                                        <td>2</td>
                                        <td>Amy K(김은지)</td>
                                        <td>2019-00-00</td>
                                        <td>0/0</td>
                                        <td>2019-00-00</td>
                                        <td>
                                            <button class="btn-default">반납하기</button>
                                            <button class="btn-default mt-10">반납취소</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <CarrotPaging></CarrotPaging>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 
import { reactive } from 'vue'
import ReturnAddPopup from '@/components/popup/ResourceManagement/ReturnAddPopup.vue'
import ReturnCancelPopup from '@/components/popup/ResourceManagement/ReturnCancelPopup.vue'


export default {
    components: {
        ReturnAddPopup, ReturnCancelPopup
    },
    setup() {
        const pop = reactive({
            // Popup 노출 여부
            isShow: true
        });
        
        return {pop};
    }
}
</script>

<style lang="scss" scoped>
</style>